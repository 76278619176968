import './style.css';
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import WaterWave from "react-water-wave";

function Products() {

  return (
      <>
        <WaterWave className="products-ripple">
          {methods => (
            <div className="page-title products-image ripple-effect jquery-ripples">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <h1>Products</h1>
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li><i className="fa fa-angle-right"></i></li>
                      <li className="active">Products</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          )}
        </WaterWave>

        <section id="services" className="services-area ptb-80">
          <div className="container">
            <div className="section-title">
              <h2>Our <span>Textbooks</span></h2>
              <p className="text-justify">
                We have developed textbooks according to the actual need of our society.
                Key features of our textbooks are as follows:
              </p>
              <ul className="customList customListDisc mt-2 mb-4">
                <li>
                  Our textbooks are based on Islamic World View and epistemology.
                  However, it doesn’t mean that it lacks modern standards in any respect.
                </li>
                <li>
                  Our textbooks are subject-centric, which means we focus the subject matter in a way that the
                  very content of the subject is delivered to the child being vibrant for his/her intellect.
                </li>
                <li>
                  Our textbooks are developed in local perspective.
                  The context and content of the lessons are very relevant to our society.
                </li>
                <li>
                  Subject-centric approach in our textbooks assists the students
                  <ul className="customList customListSquare mt-1 mb-1">
                    <li>
                      To understand and define his/her surroundings. (Science)
                    </li>
                    <li>
                      To understand and creatively produce in classical Urdu. (Ehya e
                      Urdu)
                    </li>
                    <li>
                      To relate teachings of Islam in his/her daily life. (Islamic Studies)
                    </li>
                    <li>
                      To comprehend and appreciate the cultural diversity. (Social Studies)
                    </li>
                  </ul>
                </li>
                <li>
                  Our textbooks are concept oriented. Hence, the development of cognitive skills has
                  always been the point of emphasis.
                </li>
                <li>
                  Assessments and exercises are designed to meet SLOs, beyond a mere test of child’s memorization skill.
                </li>
                <li>
                  Students read our textbooks with zeal and interest, to achieve this,
                  we have tried very hard to ensure that our textbooks are aesthetically well-developed and
                  interesting in terms of content. We have used illustrations and
                  imported paper to let the child connect with the book.
                </li>
              </ul>
            </div>
            <div className="row" id="_Urdu_">
              <div className="col-lg-4 text-center position-relative">
                <div className="ideo-img"><img src="assets/img/products/urdu-books.png"
                                               className="img-fluid" alt="Urdu Books"/>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="services-text">
                  <h3>Urdu</h3>
                  <p>
                    The mastery of own language is essential because it serves as a means of expression and a
                    tool for gratifying one's aesthetic aspirations. In our country, the reliance on the English
                    language eroded the prestige of Urdu to the verge of extinction for decades. Urdu is confined
                    to the context of a subject only. Its content and teaching are given scant consideration which
                    has resulted in individuals having little or no command over the language.
                  </p>
                  <p>
                    Urdu curriculum and textbooks always seems to be a combination of sociology and ethics instead
                    f Urdu literature. In typical textbooks, the children are given arid lessons to read, which are
                    difficult for a young child to develop an interest. That is why these books are unable to develop
                    imagination, aesthetics and hence incapable of mastering the Urdu language.
                  </p>
                  <p>
                    Ehya e Urdu:
                  </p>
                  <p>
                    At Ehya Education Services, we have made a concerted effort to fill these voids in our Urdu
                    books in the following ways:
                  </p>
                  <ul className="customList customListDisc mt-1">
                    <li>
                      The textbooks are developed with the aim to instill
                      love of Urdu language in the heart of student.
                    </li>
                    <li>
                      The exercises are not there to test the memory of the
                      students but they are designed in a way to ignite creative skills of the child and increase the
                      competency of Urdu language.
                    </li>
                    <li>
                      The design of the books are appealing.
                      Illustrations and color schemes are eye- catching.
                    </li>
                    <li>
                      The lessons are chosen to increase the competency
                      and interest of Urdu language.
                    </li>
                    <li>
                      To keep the interest of children the lessons are
                      written in story mode.
                    </li>
                    <li>
                      We have tried our best that the lessons develop
                      adoration to the Urdu literature, aesthetics and creativity in a child.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row" id="_Science_">
              <div className="col-lg-12">
                <div className="services-text">
                  <h3>Science</h3>
                  <p>
                    Science, when taught as a subject, must inculcate in a student some abilities that serves
                    as the purpose of teaching this subject. Science is about observation of surrounding,
                    studying the phenomena related to physical world, understanding fundamental laws governing
                    changes within ourselves and the world outside. Regrettably, Science when taught in our education
                    system fails to develop any basic ability presumed to be associated with the teaching of science.
                    Rather this subject overburdens our students with a noticeable amount of material in the form of
                    'definitions' that demands to be memorized and reproduced upon the examination paper on the exam day.
                    It has nothing to do with the development of understanding fundamental laws and phenomena related
                    to observable world. Most of the students studying science in our country cannot explain the
                    phenomenon of rain- a repetitive occurrence, the concepts of solid, liquid, gas, conductor,
                    non-conductor, current, atom, flow of electrons, electromagnetic flux etc. are not alien to them
                    in terms of definitions, but these ideas are totally alien and irrelevant to them as concepts
                    crucial to understand and explain our surroundings and even ourselves.
                  </p>
                  <p>
                    Ehya Education Services has taken all of these elements into account when writing textbooks of
                    Science. We have written these textbooks in interrogative style instead of an article style.
                    This will not only instill in the child a desire to learn new things but will also instill in
                    him the habit of observation. These textbooks of Science not only presents the necessary
                    information and concepts but also sheds light on their application in daily life. This connection
                    with everyday life will not only arouse the students' natural curiosity but also unleash the
                    scientist in them.
                  </p>
                  <p>
                    We believe that the best way to teach science is to perform it rather than rote it.
                    To that end, we've included scientific inquires in our textbooks to help improve children's
                    analytical skills. We have added activities and experiments throughout the textbooks to help
                    students learn to create evidence and draw conclusions. It was considered to keep activities
                    simple and easy. One of the notable feature of our textbooks is its assessments. We have added
                    both formative assessments, to keep students engaged during class, and summative assessments
                    at the end of chapters to assess students’ cognitive skills.
                  </p>
                  <p>
                    Furthermore, the language of the book is kept simple and child-friendly so that the children
                    could read without any help. Design elements have been worked on to make the book more
                    attractive, and images have been added to make the text more comprehensible.
                  </p>
                </div>
              </div>
            </div>
            <div className="row" id="_Social_Studies_">
              <div className="col-lg-12">
                <div className="services-text">
                  <h3>Social Studies</h3>
                  <p>
                    Human beings are a complex creation of Allah. Yet they are the most efficient creation,
                    who resides in complex and diverse social settings. These social settings are affected by
                    multiple factors, ethnical diversity, different environmental conditions, different cultures
                    and cultural backgrounds and a variety of geographical regions etc. To understand human society
                    and drive it towards betterment, Social Studies is an important subject taught at school level
                    around the globe. The main components of this subject are geography and history
                    (along with politics, economics, sociology, anthropology etc.) of a certain region to comprehend
                    the multi-perspective and diverse nature of the particular region. This subject is supposed to:
                  </p>
                  <ul className="customList customListDisc mt-1 mb-3">
                    <li>
                      Develop capacities supportive to the coexisting environment of a society by understanding
                      diversity in geographical and historical backgrounds.
                    </li>
                    <li>
                      Develop an understanding of the history and how it affects our present and future.
                    </li>
                    <li>
                      Develop a relationship of human society and environment and their mutual effect.
                    </li>
                    <li>
                      Develop aptitude of critical thinking and logical decision making by understanding the local
                      context.
                    </li>
                  </ul>
                  <p>
                    By comprehending above goals a student would be able to play an effective role as a citizen.
                    His/her decision making power, critical thinking and communication skill should escalate.
                    Unfortunately, Social Studies has become one of the biggest source of boredom for our students
                    in school life. Because social studies is taught as something irrelevant to the student’s
                    practical life and it is all about difficult names of places and events of the past that a
                    student feels he/she has nothing to do with.
                  </p>
                  <p>
                    Ehya Education Services has made every possible effort to resolve all these concerns and has
                    attempted to create books that are both motivating and interactive. These books are intended
                    to help pupils improve their cognitive skills and develop logical thinking. The chapters are
                    organized in such a way that students will clearly grasp and connect the topics to their true
                    meaning and apply these in their everyday life. These books will enhance their concept building
                    and creative skills.
                  </p>
                  <p>
                    We've included some highly fascinating and thought-provoking tasks that will motivate students
                    to use their imagination and ingenuity to solve them. Our textbooks will introduce students to a
                    new universe in which they may view a range of things. The activities are extremely engaging and
                    improve students' logical thinking and analytical reasoning by allowing them to relate the
                    problem to its issue and then design solutions to the challenges.
                  </p>
                  <p>
                    The information is added according to the kids' age groups. The text volumes contain engaging
                    illustrations that will keep students involved in the book and prevent them from losing interest.
                    Students will be able to investigate historical civilizations and get a comprehensive
                    understanding of the people who lived in the past, including their lifestyles, economic, social,
                    and political systems. All of the activities include interactive questions that encourage
                    inquiry-based and collaborative learning. All examples, activities and illustrations have local
                    perspective to produce indigenous intelligentsia.
                  </p>
                </div>
              </div>
            </div>
            <div className="row" id="_Islamic_Studies_">
              <div className="col-lg-8">
                <div className="services-text">
                  <h3>Islamic Studies</h3>
                  <p>
                    Islamic Studies not only holds a critical importance for the survival of our national identity,
                    but it is also a religious duty. Though the curriculum of Islamic Studies and textbooks provide a
                    plethora of information about Islam, no effort is made to effectively teach the religion and
                    inspire students to practice it. These are designed to help students memorize material and pass exams.
                  </p>
                  <p>
                    In contrast, a good Islamic curriculum and textbook should encompass much more. At the very least,
                    this book should be much more than a religious handbook; it should provide answers to the
                    queries and problems that children may have about Islam and its role in the modern world.
                  </p>
                  <p>
                    Ehya Education Services, in its textbooks of Islamic Studies, aims to address these issues
                    and make Islamic Studies viable for students. The following areas have been given precise
                    focus while developing the books.
                  </p>
                  <ul className="customList customListDisc mt-1">
                    <li>
                      We use examples from everyday life to elucidate
                      concepts in order to persuade students to practice Islam. For this, the students' intellectual
                      degree and class level have been taken into account in such a way that the beliefs and concepts
                      are gradually articulated.
                    </li>
                    <li>
                      Typically, Islamic Studies lessons, from inception
                      to delivery, focus on conveying information. Apart from the traditional manner, we have
                      retained the style of Islamic Studies with stories, engaging lessons and design elements rather
                      than merely thematic lessons in our books. Furthermore, we have tried to ensure that the
                      student understands the topic and its repercussions.
                    </li>
                    <li>
                      Another element of our books that distinguishes it
                      from others is Exercises. Exercise questions in textbooks are typically used to evaluate
                      memorization. We've tried to make sure that students grasp the learning outcomes and
                      fundamental concepts through practice and activities. A permanent component has also been
                      incorporated to help students strengthen their relationship with the Quran.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 text-center position-relative">
                <div className="ideo-img"><img src="assets/img/products/islamic-books.png"
                                               className="img-fluid" alt="Islamic Studies Books"/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
}

export default Products
