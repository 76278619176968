import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        const urlArray = window.location.href.split('#');
        if(urlArray.length > 1){
            document.getElementById(urlArray[1]).scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [pathname]);

    return null;
}


export default ScrollToTop
