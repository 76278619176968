import {useEffect} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import './style.css';
import { Player } from 'video-react';
// import '~video-react/dist/video-react.css';

function Videos() {
    const { pathname } = useLocation();
    const {id} = useParams();
    const videosArray = [
        '0001', '0002', '0003', '0004', '0005',
        '0006', '0007', '0008', '0009', '0010',
        '0011', '0012', '0013'];

    useEffect(() => {
        if(pathname.includes('/videos/') && !videosArray.includes(id)) {
            var header = document.getElementById("header1");
            header.style.display = "none";
            var footer = document.getElementById("footer1");
            footer.style.display = "none";
        }
    }, [pathname]);
  return (
      <>
          {videosArray.includes(id) ? (
              <>
                  <section id="contact" className="contact-area ptb-80 bg-f6f6f6">
                      <div className="container">
                          <div className="row">
                              <div className="offset-lg-2 offset-md-1 col-lg-8 col-md-10 mt-5 text-center">

                                  <Player
                                      playsInline
                                      poster="/assets/img/logo.png"
                                      src={`/assets/videos/${id}.mp4`}
                                  />
                              </div>
                          </div>
                      </div>
                  </section>
              </>
          ) : (
                <div className="cs-wrap">
                  <img src="/assets/img/ehya-text.png"/>
                </div>
          ) }
      </>
  );
}

export default Videos
