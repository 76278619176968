import './style.css';
import "aos/dist/aos.css";
import './style.css';
import { Link } from "react-router-dom";
import WaterWave from "react-water-wave";

function Ideology() {

  return (
      <>
        <WaterWave className="ideology-ripple">
          {methods => (
            <div className="page-title ideology-image ripple-effect jquery-ripples">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <h1>Ideology</h1>
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li><i className="fa fa-angle-right"></i></li>
                      <li className="active">Ideology</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          )}
        </WaterWave>
        <section id="blog" className="blog-area ptb-80">
          <div className="container">
            <div className="section-title">
              <h2><span>Ideology</span> of Ehya</h2>
              <p className="text-justify">
                Ehya believes that education should be based upon indigenous thoughts and values.
                The points at which Akbar and Iqbal differed with Sir Syed are very valuable. Moreover,
                the critique of Iqbal on the West are an integral part of Ehya’s ideology and working.
                Key components of the framework that would form the foundation the education system based
                on Ehya’s core values include the following.
              </p>
            </div>
            <div className="ideo-step">
              <div className="row">
                <div className="col-lg-4 text-center position-relative">
                  <div className="ideo-img">
                    <img src="assets/img/ideology/relevance.png" className="img-fluid" alt="Relevance"/>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="single-ideology-item" >
                    <h3 className="coun-num">1</h3>
                    <h4>Relevance</h4>
                    <p>
                      To create a cohesive society, a thorough knowledge of its culture, customs,
                      and festivals is key factor. A “model” individual for Ehya is someone who is an active
                      member of the Muslim Ummah and a vibrant part of the local community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ideo-step">
              <div className="row">
                <div className="col-lg-8 order-lg-0 order-1">
                  <div className="single-ideology-item" >
                    <h3 className="coun-num">2</h3>
                    <h4>Assessment</h4>
                    <p>
                      Individuals cannot be expected to become true seekers of knowledge if an assessment system fails to
                      train the mind to think beyond its memory level. The assessment system should be teacher-centered,
                      and student’s creativity should not be suppressed for the sake of standardized assessments.
                      Students should be provided with avenues to discover new horizons so that they can understand
                      their value and mission on Earth. If one aspect of their personality is weak, then some other vital
                      aspect of their personality should be nurtured.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 text-center position-relative order-lg-1 order-0">
                  <div className="ideo-img">
                    <img src="assets/img/ideology/assessment.png" className="img-fluid" alt="Assessment"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="ideo-step">
              <div className="row">
                <div className="col-lg-4 text-center position-relative">
                  <div className="ideo-img">
                    <img src="assets/img/ideology/stem-based-education-vs-social-sciences.png" className="img-fluid" alt="STEM Based Education vs. Social Sciences"/>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="single-ideology-item" >
                    <h3 className="coun-num">3</h3>
                    <h4>STEM Based Education vs. Social Sciences</h4>
                    <p>
                      Importance of science and STEM based education in the present times
                      cannot be negated, but humanities and social sciences should also be given
                      due importance. Learning of science begins with observations, general
                      understanding of the environment and practical applications before
                      bombarding pupils with technical jargon. Students must be trained to achieve
                      a know-how of their surroundings by digging deeper into the realities that
                      surround them and manifest the sublime authority and power of Almighty Allah.
                      Moreover, the focus of education must shift from being economic centric to person centric.
                      STEM based education is not the rationale approach for our society.
                      It also means to inculcate developmental prospects for an individual in the domain of education.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ideo-step">
              <div className="row">
                <div className="col-lg-8 order-lg-0 order-1">
                  <div className="single-ideology-item" >
                    <h3 className="coun-num">4</h3>
                    <h4>Developing Creativity and Aesthetics</h4>
                    <p>
                      New ways should be devised to refine creativity and aesthetics in an individual.
                      Teaching of good literature is important in this regard, however the real task is to
                      find appropriate literature for children belonging to different age groups.
                    </p>
                    <p>
                      The human mind cannot reach its maturity without developing an
                      appreciation of beauty in words and worlds. Inculcation of local and
                      Muslim literature, calligraphy, architecture and other forms of aesthetics
                      in the education process is therefore necessary to achieve this goal.
                      Studying in outdoor milieus can encourage children to think-out-of-box as well as develop
                      vital life skills.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 text-center position-relative order-lg-1 order-0">
                  <div className="ideo-img">
                    <img src="assets/img/ideology/developing-creativity-and-aesthetics.png" className="img-fluid" alt="Developing Creativity and Aesthetics"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="ideo-step">
              <div className="row">
                <div className="col-lg-4 text-center position-relative">
                  <div className="ideo-img">
                    <img src="assets/img/ideology/tradition-vs-modernity.png" className="img-fluid" alt="Tradition Vs Modernity"/>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="single-ideology-item" >
                    <h3 className="coun-num">5</h3>
                    <h4>Tradition Vs Modernity</h4>
                    <p>
                      Modernity aspires to define the past and determine the future in its own paradigm.
                      In order to break the shackles of modernity, one must be acquainted and inspired by
                      prominent personalities in the course of history, religious or academic texts or writings
                      and cultural values that lie beyond the horizon of time. Moreover, connecting modern
                      history with the salient features of Islamic history are an essential requisite of our curriculum.
                      The role of Muslims in history as well as in the present times is highlighted in our curriculum.
                    </p>
                  </div>
              </div>
              </div>
            </div>
            <div className="ideo-step">
              <div className="row">
                <div className="col-lg-8 order-lg-0 order-1">
                  <div className="single-ideology-item" >
                    <h3 className="coun-num">6</h3>
                    <h4>Academic Excellence</h4>
                    <p>
                      In our education system a student is not treated as a mature individual till the age
                      of 20 and even more. In Pakistan, unfortunately, even a 25 years old educated individual
                      lacks the ability to critically analyze academic text or writings. Students should be
                      coached so that they are intellectually mature by the age of 18 and are able to conduct
                      critical analysis. By this age, they must have explored themselves and should be clear about
                      their future endeavors.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 text-center position-relative order-lg-1 order-0">
                  <div className="ideo-img">
                    <img src="assets/img/ideology/academic-excellence.png" className="img-fluid" alt="Academic Excellence"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="ideo-step">
              <div className="row">
                <div className="col-lg-4 text-center position-relative">
                  <div className="ideo-img">
                    <img src="assets/img/ideology/islamic-ideology.png" className="img-fluid" alt="Islamic Ideology"/>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="single-ideology-item" >
                    <h3 className="coun-num">7</h3>
                    <h4>Islamic Ideology</h4>
                    <p>
                      The ideal curriculum in an Islamic society would lead learners to grasp the actual meaning
                      of their Islamic identity, allowing them to be religious as well as patriotic so that they
                      may understand their true purpose on Earth. It must include elements that are necessary to
                      formulate worldly perspectives within the Islamic paradigm, so that they can find answers to
                      the questions that arise due to the dichotomy of knowledge to which they are exposed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ideo-step">
              <div className="row">
                <div className="col-lg-8 order-lg-0 order-1">
                  <div className="single-ideology-item" >
                    <h3 className="coun-num">8</h3>
                    <h4>Individualistic Approach vs. Mass Education</h4>
                    <p>
                      The contemporary mass education system is highly influenced by
                      nationalism being imposed through the power exercised by the states and is
                      mainly the servant of capital and power. It is imperative to have an
                      individual-centric approach that can produce children who are capable of
                      discovering their true talents and potentials. A well-groomed person can
                      excel in his/her field of interest and possesses the ability to polish his/her innate capabilities.
                      Collective goals are also important but they need to be added in due proportion in the curriculum.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 text-center position-relative order-lg-1 order-0">
                  <div className="ideo-img">
                    <img src="assets/img/ideology/individualistic-approach-vs-mass-education.png" className="img-fluid" alt="Individualistic Approach vs. Mass Education"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="ideo-step">
              <div className="row">
                <div className="col-lg-4 text-center position-relative">
                  <div className="ideo-img">
                    <img src="assets/img/ideology/language-skills.png" className="img-fluid" alt="Language Skills"/>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="single-ideology-item">
                    <h3 className="coun-num">9</h3>
                    <h4>Language Skills</h4>
                    <p>
                      Language is not just the art of communicating or expressing ideas and opinions but it is also
                      a barometer for determining the depth of a person. So, the objective of language learning
                      should not be limited to functional aspects only. A literate person must be able to understand
                      and interact beyond textual information and must understand the reality and meaningfulness
                      behind those words. Appreciation of a text must be associated with both the affective part
                      of human mind as well as its cognitive part.
                    </p>
                    <p>
                      In order to achieve this, EHYA is working on the following languages:
                    </p>
                    <ul className="customList customListDisc mt-2 mb-4">
                      <li>
                        <b>Urdu: </b>
                        Being our national language, it is vital to develop a comprehensive syllabus that can
                        develop both reading and writing skills of our students.
                      </li>
                      <li>
                        <b>Arabic: </b>
                        Being the language of the Holy Quran and Hadith, our curriculum
                        inculcates relevant syllabus so that our students can understand the true teachings
                        of the Holy Quran.
                      </li>
                      <li>
                        <b>English: </b>
                        It is an International language and must be given its due share of importance.
                      </li>
                      <li>
                        <b>Local Languages: </b>
                        Optional Curriculum for Local languages as well as of Persian that also have a very rich
                        tradition for Muslims.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
}

export default Ideology
