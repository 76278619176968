import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

// import App from './App';
import Header from './common/Header'
import ScrollToTop from './common/ScrollToTop'
import Home from './pages/home'
import Services from './pages/services'
import Diagnosis from './pages/diagnosis'
import Ideology from './pages/ideology'
import Contact from './pages/contact'
import Footer from './common/Footer'
import reportWebVitals from './reportWebVitals';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Products from "./pages/products";
import Resources from "./pages/resources";
import Videos from "./pages/videos";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="services" element={<Services />} />
            <Route path="diagnosis" element={<Diagnosis />} />
            <Route path="ideology" element={<Ideology />} />
            <Route path="products" element={<Products />} />
            <Route path="resources" element={<Resources />} />
            <Route path="contact" element={<Contact />} />
            <Route path="videos">
                <Route path=":id" element={<Videos />} />
            </Route>
        </Routes>
        <Footer />
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
