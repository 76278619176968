import {Link, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

function Header() {
  const { pathname } = useLocation();
  return (
      <>
        <header className="header-sticky" id="header1">
          <nav className="navbar navbar-expand-lg navbar-light bg-light ">
            <div className="container">
              <Link className="navbar-brand  logo-light" to="/">
                <img className="img-fluid" src="/assets/img/logo.png" alt="EES"/>
              </Link>
              <Link className="navbar-brand logo-dark" to="/">
                <img className="img-fluid " src="/assets/img/logo-dark.png" alt="EES"/>
              </Link>

              <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                      aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item"><Link className={`nav-link ${pathname == '/' ? 'active' : ''}`} to="/">Home</Link></li>
                  <li className="nav-item"><Link className={`nav-link ${pathname == '/diagnosis' ? 'active' : ''}`} to="diagnosis">Diagnosis</Link></li>
                  <li className="nav-item"><Link className={`nav-link ${pathname == '/ideology' ? 'active' : ''}`} to="ideology">Ideology</Link></li>
                  <li className="nav-item"><Link className={`nav-link ${pathname == '/services' ? 'active' : ''}`} to="services">Services</Link></li>
                  <li className="nav-item"><Link className={`nav-link ${pathname == '/products' ? 'active' : ''}`} to="products">Products</Link></li>
                  <li className="nav-item"><Link className={`nav-link ${pathname == '/resources' ? 'active' : ''}`} to="resources">Resources</Link></li>
                  <li className="nav-item"><Link className={`nav-link ${pathname == '/contact' ? 'active' : ''}`} to="contact">Contact</Link></li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </>
  );
}

export default Header;
