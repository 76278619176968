import './style.css';
import "aos/dist/aos.css";
import './style.css';
import { Link } from "react-router-dom";
import WaterWave from 'react-water-wave';

function Diagnosis() {

  return (
      <>
        <WaterWave className="diagnosis-ripple">
          {methods => (
              <div className="page-title diagnosis-image">
                <div className="d-table">
                  <div className="d-table-cell">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <h1>Diagnosis</h1>
                          <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><i className="fa fa-angle-right"></i></li>
                            <li className="active">Diagnosis</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          )}
        </WaterWave>
        <section className="diag-area ptb-80">
          <div className="container">
            <div className="section-title">
              <h2>Our <span>Diagnosis</span></h2>
              <p className="text-justify">
                The world in which we live is constructed on the ideas that were developed in the West around
                five hundred years ago. Those ideas and theories rejects metaphysical
                discourses including religion. All the knowledge that surrounds us has
                been influenced by the same set of ideologies in one way or the other,
                particularly the ones that became popular in relatively recent times. Muslims,
                in this present stage of history, are the consumers of knowledge rather than producers and
                hence are on the receiving end of its ideological onslaught.
              </p>

            </div>
            <div className="diag-step-1">
              <div className="row">
                <div className="col-lg-8">
                  <div className="single-diag-item text-justify">
                    <h4>The genesis of modern world view</h4>
                    <p>
                      The advancements in knowledge from the fifteenth to the twentieth
                      century led to new notions regarding Man, Universe and the Absolute
                      Reality as well as gave birth to new paradigms and perspectives.
                      The belief that Man has become a “social and the only successful animal”
                      with no sacredness attributed to him has infiltrated all civilizations and faiths
                      throughout the world. Man, who once was a super creature with a spiritual and moral self, has
                      declined to the stature of a mere animal with self-consciousness,
                      and moving only in the direction of his instinctual impulses with
                      no transcendent goal or idea is attached with him.
                    </p>
                    <p>
                      On the other hand, Religion and metaphysics are increasingly
                      categorized as personal, subjective, and speculative concepts that
                      are fundamentally different from empirically obtained objective
                      knowledge. All the useful palliatives for man’s emotions, his aesthetically
                      satisfying imaginative creation, and necessary bulwarks for morality or social
                      cohesion are declared as superstitious and meaningless.
                    </p>
                  </div>
                </div>
                <div className="diag-img-1">
                  <img src="assets/img/diagnosis/the-genesis-of-modern-world-view.png" className="The genesis of modern world view"/>
                </div>
              </div>
            </div>
            <div className="diag-step">
              <div className="diag-img-2">
                <img src="assets/img/diagnosis/theoretical-influence-of-western-knowledge.png" className="The genesis of modern world view"/>
              </div>
              <div className="row">
                <div className="col-lg-4"></div>
                <div className="col-lg-8">
                  <div className="single-diag-item text-justify">
                    <h4>Theoretical Influence of Western Knowledge</h4>
                    <p>
                      When modern knowledge is communicated in religious societies like ours it produces
                      individuals who are confused and bifurcated between worldly matters and religious affairs.
                      On one hand they have a firm attachment with their religion and consider their religious
                      beliefs above and beyond the worldly notions of rationality, while on the other hand they
                      consider western knowledge as a source of achieving success or progression in the current
                      times and a system that have explained successfully the universe using only the rational and
                      empirical faculties of man. This dichotomy in knowledge and discourse is creating ideologically
                      opposite people in Muslim countries. Even those who consider themselves as religious are also
                      living in the same ideological paradigm that is provided by the western civilization.
                      As a result, the concept of Man, his relationship with the society and the Universe,
                      all are seen in this western perspective.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="diag-step-1">
              <div className="row">
                <div className="col-lg-8">
                  <div className="single-diag-item text-justify">
                    <h4>The Educational System of Pakistan</h4>
                    <p className="text-justify">
                      Education is the cornerstone of any country's development.
                      The Education System of Pakistan is neglected to assume its part in national development.
                      At both the academic and practical levels, our country's education system faces worldwide and
                      indigenous issues. On the theoretical level it faces ideological concerns, while on the
                      practical level, it faces education-centric challenges such as curricular incoherence,
                      teacher training gaps, irrational topic selection, and a poorly defined evaluation system.
                    </p>
                  </div>
                </div>
                <div className="diag-img-1">
                  <img src="assets/img/diagnosis/the-educational-system-of-pakistan.png" className="The genesis of modern world view"/>
                </div>
              </div>
            </div>
            <div className="diag-step">
              <div className="diag-img-2">
                <img src="assets/img/diagnosis/some-flaws-of-educational-system-in-pakistan.png" className="The genesis of modern world view"/>
              </div>
              <div className="row">
                <div className="col-lg-4"></div>
                <div className="col-lg-8">
                  <div className="single-diag-item text-justify">
                    <h4>Some Flaws of Educational System in Pakistan</h4>
                    <div className="f-text">
                      <h5>At Theoretical Level</h5>
                      <ul className="customList customListDisc mt-2 mb-4">
                        <li>
                          The goals of education that drives our education system are not clear in describing
                          that what kind of individual they want to produce. They lack a balance between
                          tradition and modernity as well as between science and humanities.
                        </li>
                        <li>
                          The sole purpose of education in our country has become to find lucrative employment,
                          even at the expense of civic and familial responsibilities.
                          The measure of success is the cash value of a degree.
                        </li>
                        <li>
                          Almost no emphasis is paid on inculcating Islamic values and ideals in our youngsters.
                          Our curriculum of Islamic Studies provides a generic understanding of religion and
                          deals with basics only. It does not clarify the misconceptions endorsed by other subjects
                          in the curricula. For example when studying science, students are learning about theories
                          pertaining to the accidental creation of Universe or Darwin’s theory of Evolution, which
                          is in complete opposition to the notion provided in Islamic Studies. We find no refutation
                          of it in Islamic Studies or any other subject.
                        </li>
                        <li>
                          There are zero efforts in curriculum as well as in education system to raise awareness
                          of what happened nationally and internationally.
                        </li>
                        <li>
                          There is no sanctity in a system, where the sole purpose of education is to amass wealth.
                          Providing education, especially schooling, has become limited as a business only.
                        </li>
                        <li>
                          Our schooling system has lost sight of the basic purpose
                          of schooling. Schooling is meant to provide a proper base to students and nurture
                          their academic abilities and skill set. Unfortunately, our schooling system has
                          failed to maintain a balance between academics and play-time putting the mental
                          and physical health of children at risk.
                        </li>
                      </ul>
                      <h5>At Practical Level</h5>
                      <ul className="customList customListDisc mt-2 mb-4">
                        <li>
                          Our education system lacks well-designed curricula to meet the needs of modern times,
                          it has failed to produce persons with excellence. Education has limited to remembering
                          something while the basic purpose should be learning, analyzing, and developing skillful
                          intelligence.
                        </li>
                        <li>
                          Examination system is the driving force behind the student’s learning and a way
                          to assess the student’s learning. Whole of the education system from early school
                          to university is designed and governed by the thumb rule i.e. to get good grades.
                          As a result our educational system fails to produce individuals with critical thinking
                          skills, skillful intelligence, and the ability to compete on practical level.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
);
}

export default Diagnosis
